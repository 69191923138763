import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Features2 from "./Features2";
import "./FeaturesSection2.scss";

function FeaturesSection2(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      alt={props.alt}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="text-center text-lg-left">
            <figure className="FeaturesSection2__image-container mx-auto">
              <Image src={props.image} fluid={true} alt={props.alt} />
            </figure>
          </Col>
          <Col className="offset-lg-1 mt-5 mt-lg-0 ">
            <Features2
              items={[
                {
                  title: "Dedicated Mentor",
                  description:
                    "One on one time with some of the best in the industry. Hear it first from the people figuring it out.",
                  iconClass: "fas fa-comments",
                  iconColor: "primary",
                  alt: "dedicated mentor",
                },
                {
                  title: "Scheduled Program",
                  description:
                    "Take comfort in knowing what you're doing will add up to something bigger in the end.",
                  iconClass: "fas fa-calendar",
                  iconColor: "purple",
                  alt: "scheduled program",
                },
                {
                  title: "Remote First",
                  description:
                    "Gain the skills required to be a knowledge worker in todays remote first industry.",
                  iconClass: "far fa-surprise",
                  iconColor: "danger",
                  alt: "remote first",
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default FeaturesSection2;
