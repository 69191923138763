import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
import HeroSection from "./../components/HeroSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import NewsletterSection from "./../components/NewsletterSection";
import Footer from "./../components/Footer";

function TeamPage(props) {
  return (
    <>
      <NavbarCustom
        bg="white"
        variant="light"
        expand="md"
        logo="img/jm_black.png"
      />
      <HeroSection
        bg="primary"
        textColor="white"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Unlocking Potential"
        subtitle="We're a team of highly dedicated people, focused soley on helping our mentees develop into the best possible version of themselves."
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Get a curated list of the best learning resources straight to your inbox every month. Keep up with us and the ever changing world of data. "
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed 🙌"
        image="img/undraw-newsletter_vovu.svg"
        imageSuccess="img/undraw-high_five_u364.svg"
        alt="newsletter diagram"
      />
      <Footer
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        description="A short description of what you do here"
        copyright="Junior Mentorship © 2020"
        logo="img/jm_black.png"
      />
    </>
  );
}

export default TeamPage;
