import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Testimonials from "./Testimonials";

function TestimonialsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Testimonials
          items={[
            {
              avatar:
                "img/will_180w.webp",
              name: "Will Barker",
              testimonial:
                "Between invaluable mentorship from industry experts and hands-on experience, I’ve gained an extremely solid foundation to build my career upon.",
              company: "Telus Digital",
            },
            //    {
            //      avatar: 'https://uploads.divjoy.com/pravatar-150x-48.jpeg',
            //      name: 'Shawna Murray',
            //      testimonial: 'Ajay was well organized in terms of setting up weekly calls and laying out basic concepts in the form of short presentations on Google Hangouts',
            //      company: "Company"
            //    },
            {
              avatar:
                "img/rahul_180w.webp",
              name: "Rahul Iyer",
              testimonial:
                "Ajay was well organized in terms of setting up weekly calls and laying out basic concepts in the form of short presentations on Google Hangouts",
              company: "St. Joseph Communications",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
