import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Clients from "./Clients";

function ClientsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Clients
          items={[
            {
              name: "WestJet logo",
              image: "img/westjet_160w.webp",
              width: "160px",
            },
            {
              name: "Canada Post logo",
              image: "img/canadapost_160w.webp",
              width: "160px",
            },
            {
              name: "Charter logo",
              image: "/img/charter_160w.webp",
              width: "160px",
            },
            //    {
            //      name: 'BMO',
            //      image: 'https://i.ibb.co/MZzHbPB/bmo.png',
            //      width: "160px"
            //    }
          ]}
        />
      </Container>
    </Section>
  );
}

export default ClientsSection;
