import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
import HeroSection from "./../components/HeroSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import FeaturesSection from "./../components/FeaturesSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import Footer from "./../components/Footer";

function MentorshipPage(props) {
  return (
    <>
      <NavbarCustom
        bg="white"
        variant="light"
        expand="md"
        logo="img/jm_black.png"
      />
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Mentorship"
        subtitle="Get started with a mentor today."
      />
      <FeaturesSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        image="img/undraw-chatting_2yvo.svg"
        alt="chatting diagram"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="How We Do It"
        subtitle="Follow our lead and take comfort in our tried and tested strategies."
      />
      <TestimonialsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Get a curated list of the best learning resources straight to your inbox every month. Keep up with us and the ever changing world of data. "
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed 🙌"
        image="img/undraw-newsletter_vovu.svg"
        imageSuccess="img/undraw-high_five_u364.svg"
        alt="newsletter diagram"
      />
      <Footer
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        description="A short description of what you do here"
        copyright="Junior Mentorship © 2020"
        logo="img/jm_black.png"
      />
    </>
  );
}

export default MentorshipPage;
