import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import TeamBios from "./TeamBios";

function TeamBiosSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <TeamBios
          items={[
            {
              avatar:
                "img/ajay.webp",
              name: "Ajay Ajaal",
              role: "Founder",
              bio:
                "A data architect specializing in digital data pipelines. I focus on trying to break down complex concepts into simplistic ideas.",
              twitterUrl: "https://twitter.com/ajayajaal",
              //facebookUrl: 'https://facebook.com',
              linkedinUrl: "https://www.linkedin.com/in/ajayajaal/",
            },
            {
              avatar:
                "img/owen.webp",
              name: "Owen Wright",
              role: "Program Lead",
              bio:
                "A computer scientist by training, a jack of all trades on the job. Owen has worked with the largest airlines and banks in the world.",
              twitterUrl: "https://twitter.com/ajayajaal",
              //facebookUrl: 'https://facebook.com',
              linkedinUrl: "https://www.linkedin.com/in/ajayajaal/",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
